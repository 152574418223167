import './style.scss'
import { DetailsModal } from '../../../js/components/DetailsModal/DetailsModal'

export default !function () {
  const block = document.querySelector('.mentor-v2')

  if (!block) {
    return
  }

  const blockName = block.dataset.blockName ?? 'Блок без названия'

  // Полифилл, так как хром не умеет в медиа-запросы в source для video
  const video = block.querySelector('.mentor-v2__media--video')

  if (video) {
    const videoSources = video.querySelectorAll('source')

    const sources = {
      mobile: {
        webm: videoSources[0].src,
        mp4: videoSources[1].src
      },
      desktop: {
        webm: video.dataset.srcWebm,
        mp4: video.dataset.srcMp4
      }
    }

    const createSource = (viewport, format) => {
      const src = sources[viewport][format]

      if (src) {
        const source = document.createElement('source')
        source.src = src
        source.type = `video/${format}`

        video.appendChild(source)
      }
    }

    const switchSources = (viewport) => {
      const formats = ['webm', 'mp4']
      const hasViewportSrc = formats.some(format => sources[viewport][format])

      if (hasViewportSrc) {
        video.innerHTML = ''

        formats.forEach(format => {
          createSource(viewport, format)
        })

        video.load()
        video.play()
      }
    }

    const desktop = window.matchMedia('(min-width: 1024px)')

    const desktopMediaChangeListener = () => {
      if (desktop.matches) {
        switchSources('desktop')
      } else {
        switchSources('mobile')
      }
    }

    desktopMediaChangeListener()
    desktop.addEventListener('change', desktopMediaChangeListener)
  }

  // Detailed view
  let mediaData = []
  const button = block.querySelector('.mentor-v2__button')

  const setDisabled = (button, isDisabled) => {
    button.disabled = isDisabled
    isDisabled
      ? button.classList.add('ui-button--loading')
      : button.classList.remove('ui-button--loading')
  }

  const buttonClickHandler = async (evt) => {
    const button = evt.target.closest('.mentor-v2__button')

    setDisabled(button, true)

    try {
      if (!mediaData.length) {
        // eslint-disable-next-line
        const response = await fetch(`/landgen/api/v1/landing/${landingInfo.landing_ID}/country/${landingInfo.countrySlug}/language/${landingInfo.languageSlug}/media-gallery?block_slug=${block.id}`)

        if (!response.ok) {
          await Promise.reject(response)
        }

        mediaData = await response.json()
      }

      mediaData.length && new DetailsModal(mediaData, 0, blockName).init()
    } catch (e) {
      e.message = `[media-gallery] ${e.message}`
      window.Sentry.captureException(e)
    } finally {
      setDisabled(button, false)
    }

    window.$analytics?.push('mentor-click-details')
  }

  button.addEventListener('click', buttonClickHandler)
}()
